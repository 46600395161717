<template>
  <!--  -->
  <div class="wrapper">
    <admin-title :title="$route.meta.name"></admin-title>
    <div class="header-search">
      <el-form ref="form" inline :model="searchData" label-width="">
        <el-form-item label="商品名称：">
          <el-input style="width: 200px" clearable v-model="searchData.goods_name" size="mini" placeholder="请输入"></el-input>
        </el-form-item>
<!--        <el-form-item label="商品编码：">-->
<!--          <el-input style="width: 200px" clearable v-model="searchData.goods_no" size="mini" placeholder="请输入"></el-input>-->
<!--        </el-form-item>-->
        <el-form-item label="商品分类：">
          <GoodsCategory v-model="searchData.category_id" width="200px" size="mini"></GoodsCategory>
        </el-form-item>

        <el-form-item label=" ">
          <el-button type="primary" size="mini" icon="el-icon-search" @click="getList">搜索</el-button>
          <el-button type="info" size="mini" icon="el-icon-refresh" @click="reset">重置</el-button>
        </el-form-item>
      </el-form>
    </div>
    <div class="tabs">
      <div :class="['item', status === 0 ? 'active' : '']" @click="handleClickTab(0)">
        <el-badge>
          <span class="tab-name">全部</span>
        </el-badge>
      </div>
      <div :class="['item', status === 20 ? 'active' : '']" @click="handleClickTab(20)">
        <el-badge>
          <span class="tab-name">出售中</span>
        </el-badge>
      </div>
      <div :class="['item', status === 10 ? 'active' : '']" @click="handleClickTab(10)">
        <el-badge>
          <span class="tab-name">已下架</span>
        </el-badge>
      </div>
    </div>
    <el-button style="margin-bottom: 10px" icon="el-icon-plus" plain type="primary" size="mini" @click="$router.push({ name: 'GoodsAdd' })">新增</el-button>
    <el-table :data="list">
      <el-table-column prop="id" label="商品ID" min-width="120"></el-table-column>
      <el-table-column prop="goods_name" label="商品名称" min-width="120"></el-table-column>
      <el-table-column prop="content" label="商品图片" min-width="100">
        <template slot-scope="{ row }">
          <el-image
            style="width: 60px; height: 60px; border-radius: 3px; display: block"
            :src="row.goods_image"
            :preview-src-list="[row.goods_image]"
            fit="cover"
          ></el-image>
        </template>
      </el-table-column>
      <el-table-column prop="" label="商品分类" min-width="120">
        <template slot-scope="{ row }">
          <el-tag style="margin-right: 5px" v-for="(item, i) in row.categories" :key="i" type="primary" size="small">{{ item.name }}</el-tag>
        </template>
      </el-table-column>
      <el-table-column prop="goods_price_min" label="商品价格" min-width="100"> </el-table-column>
      <el-table-column prop="goods_sales" label="总销量" min-width="100"> </el-table-column>
      <el-table-column prop="stock_total" label="库存总量" min-width="100"> </el-table-column>
      <el-table-column prop="name" label="状态" min-width="100">
        <template slot-scope="{ row }">
          <el-tag v-if="row.status == 20" size="small" type="primary">上架</el-tag>
          <el-tag v-if="row.status == 10" size="small" type="danger">下架</el-tag>
        </template>
      </el-table-column>
<!--      <el-table-column prop="goods_no" label="商品编码" min-width="100"> </el-table-column>-->
      <el-table-column prop="sort" label="排序" min-width="100"> </el-table-column>
      <el-table-column prop="created_at" label="添加时间" min-width="160"> </el-table-column>
      <el-table-column label="操作" width="120" fixed="right">
        <template slot-scope="{ row }">
          <!-- <el-link style="margin-right: 10px" type="primary" :underline="false" @click="handleDetail(row)">详情</el-link> -->
          <el-link style="margin-right: 10px" type="primary" :underline="false" @click="$router.push({ name: 'GoodsEdit', params: { id: row.id } })">编辑</el-link>
          <el-link :underline="false" type="danger" @click="handleDel(row)">删除</el-link>
        </template>
      </el-table-column>
    </el-table>
    <pagination :total="total" :page.sync="searchData.page" :limit.sync="searchData.page_size" @pagination="getList" />

    <!--  -->
    <div style="height: 50px"></div>
  </div>
</template>

<script>
import { getListAPI, delAPI } from './api'
import { authBtnMixin } from '@/mixins/authBtnMixin'
import GoodsCategory from '@/views/goods/goods-category/select-goods-category.vue'
export default {
  name: 'Index',
  mixins: [authBtnMixin],
  components: { GoodsCategory },
  data() {
    return {
      list: [],
      total: 0,
      searchData: {
        page: 1,
        page_size: 10,
        category_id: '',
        goods_name: '',
        goods_no: ''
      },
      status: 0
    }
  },

  activated() {
    this.getList()
  },
  mounted() {},

  methods: {
    handleClickTab(val) {
      if (this.status == val) return
      this.status = val
      this.getList()
    },
    async getList() {
      const { status } = this
      let params = { ...this.searchData }
      if (status) {
        params.status = status
      }
      const res = await getListAPI(params)
      this.list = res.data || []
      this.total = res.total || 0
    },
    // del
    async handleDel({ id }) {
      this.$confirm('确认删除吗?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          delAPI(id).then(() => {
            this.$message.success('删除成功')
            this.getList()
          })
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除'
          })
        })
    },

    reset() {
      this.status = 0
      Object.assign(this._data.searchData, this.$options.data().searchData)
      this.getList()
    }
  }
}
</script>

<style lang="scss" scoped>
.wrapper {
  width: 100%;
  .header-search {
    .el-form {
      .el-form-item {
        margin-bottom: 5px;
      }
    }
  }
  .tabs {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
    border-bottom: 1px solid #dcdfe6;
    .item {
      cursor: pointer;
      position: relative;
      padding-bottom: 5px;
      margin-right: 20px;

      .tab-name {
        padding: 6px;
      }
    }
    .active {
      color: #409eff;
      font-weight: 500;
      transition: all 0.3s;
      &::after {
        content: '';
        display: inline-block;
        height: 2px;
        width: 25px;
        background: #409eff;
        position: absolute;
        bottom: -2px;
        left: 50%;
        transform: translateX(-50%);
        border-radius: 5px;
      }
    }
  }
}
</style>
